import React from "react";
import { MdAdd } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";

const PlayersTab = ({ players, onAddPlayer, auth }) => {
  return (
    <div>
      <div className="">
        <div className="flex justify-between items-center my-6">
          <h1 className="text-2xl font-bold">Players</h1>
          {(auth?.role === "staff" &&
            auth.access.some(
              (item) =>
                item.title === "playerRegistration" &&
                item.enabled &&
                (item.type === "Write Only" || item.type === "Read & Write")
            )) ||
          auth?.role === "user" ? (
            <button
              type="button"
              className="bg-gradient-to-r from-primary-start to-primary-end text-white sm:text-base text-sm rounded py-2 px-3"
              onClick={onAddPlayer}
            >
              Add a player
            </button>
          ) : null}
        </div>
        <section className="w-fit mx-auto grid grid-cols-2 md:mx-auto lg:mx-0 lg:grid-cols-8 md:grid-cols-2 justify-items-center justify-center gap-y-6 gap-x-6 mt-10 mb-5">
          {players && players.length > 0 ? (
            players.map((ele) => (
              <div
                key={ele._id}
                className="w-auto sm:w-32 bg-white shadow-md rounded-lg duration-500 hover:scale-105 hover:shadow-xl"
              >
                <img
                  src={ele.profile}
                  alt="Player"
                  className="h-32 w-40 object-cover rounded-t-lg"
                />
                <div className="p-2 cursor-default">
                  <Tooltip title={ele.name} arrow>
                    <div className="font-bold max-w-32 text-black truncate block capitalize">
                      {ele.name}
                    </div>
                  </Tooltip>
                  <div className="text-xs text-gray-400">Age: {ele.age}</div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-full text-center text-gray-500 mt-5">
              No players available
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default PlayersTab;
