/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Notfound from "../../pages/Notfound";
import spinnerIcon from "../../assets/icons/spinner.svg";
import { MdOutlineLocationOn, MdCalendarMonth } from "react-icons/md";
import { InputSubmit } from "../InputFields/InputFields";
import { useSelector } from "react-redux";
import { getTournament } from "../../helpers/apis/guest";
import { useErrorToast, useSuccessToast } from "../../hooks/useToast";
import { MdArrowBack } from "react-icons/md";
import "./Tournament.scss";
import RegisterForm from "./RegisterForm";
import About from "./About";
import MatchTab from "./MatchTab";
import TableTab from "./TableTab";

function TournamentMain() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [dataFound, setDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [isRegistered, setIsRegistered] = useState();
  const auth = useSelector((state) => state.auth);
  const [showTeamData, setShowTeamData] = useState({
    teamId: "",
    show: false,
    tournament: data._id,
    isHost: data.isHost,
  });
  const [showMatchData, setShowMatchData] = useState({
    show: false,
    match: {},
    round: {},
  });
  const location = useLocation();

  const fetchTournament = () => {
    getTournament(id, auth)
      .then((res) => {
        if (res.data.success) {
          setDataFound(true);
          setData(res.data.data);
          setIsRegistered(res.data.data?.isRegistered);
        }
      })
      .catch(() => {
        setDataFound(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchTournament();
  }, [isRegistered, showMatchData]);



  const [activeTab, setActiveTab] = useState("about");

  const renderContent = (data, setShowMatchData, showMatchData) => {
    switch (activeTab) {
      case "about":
        return <About data={data} isRegistered={isRegistered} />;
      case "matches":
        return <MatchTab data={data} setShowMatchData={setShowMatchData} showMatchData={showMatchData} />;
      case "scoreTable":
        return <TableTab data={data} />;
      default:
        return <About data={data} isRegistered={isRegistered} />;
    }
  };

  if (loading && !dataFound)
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <img src={spinnerIcon} className="w-9 animate-spin" alt="Loading..." />
      </div>
    );

  if (!loading && !dataFound) return <Notfound />;

  return (
    <>
    <div>
      <button className="flex items-center gap-2 mb-6 mx-4 md:mx-8 lg:mx-12" onClick={() => navigate(-1)}>
      <MdArrowBack/>
        Back
      </button>
    </div>
      <div className="bg-white border shadow-md rounded-lg p-6 mx-4 md:mx-8 lg:mx-12">
        <div className="flex flex-col sm:flex-row sm:justify-between items-center">
          <div className="rounded-full w-fit bg-white border-gray-400 border p-2 mb-4 sm:mb-0">
            <img
              src={data?.cover}
              alt="profile"
              className="w-24 h-24 sm:w-28 sm:h-28 rounded-full"
            />
          </div>
          <div className="flex flex-col gap-2 w-full sm:w-[85%]">
            <div className="flex justify-between">
              <p className="text-xl sm:text-2xl font-bold">{data.title}</p>
              {/* <button
                type="button"
                className="text-base flex items-center mt-2 sm:mt-0"
              >
                <img className="w-4 h-4 mr-2" src={editIcon} alt="edit" />
                <span>Edit</span>
              </button> */}
            </div>
            <div className="flex flex-col sm:flex-row gap-2 justify-between sm:items-end">
              <div>
                <div className="flex items-center gap-1">
                  <MdOutlineLocationOn />
                  <div className="whitespace-nowrap">{data?.location}</div>
                </div>
                <div className="flex items-center gap-1">
                  <MdCalendarMonth />
                  <div className="whitespace-nowrap">{data?.start_date}</div>
                </div>
                <div className="flex items-center gap-1">
                  {/* <b>Tournament Type :</b> */}
                  <div className="whitespace-nowrap">
                    {(() => {
                      switch (data?.tournament_type) {
                        case 't1':
                          return 'League';
                        case 't2':
                          return 'Knockout';
                        case 't3':
                          return 'Group Stage + Knockout';
                        default:
                          return 'Unknown Type';
                      }
                    })()}
                  </div>
                </div>
              </div>
              <div className="mt-2 sm:mt-0 flex justify-end">
                {!isRegistered && data?.registration?.status === "open" && !data?.isHost ? (
                  <InputSubmit
                    type="button"
                    value="Register"
                    className="w-1/2 sm:w-1/3 min-w-[130px] h-9"
                    onClick={() => {
                      if (!auth.name)
                        navigate("/login", {
                          state: { from: location.pathname },
                        });
                      if (auth.clubStatus !== "active")
                        navigate("/user/club", {
                          state: { from: location.pathname },
                        });
                      else setRegisterModal(true);
                    }}
                  />
                ) : data?.registration?.status === "open" ? (
                  <>
                    {!isRegistered ? (
                      <button
                        className="hover:shadow border border-secondary rounded text-secondary w-full hover:bg-gradient-to-r from-secondary-start to-secondary-end hover:text-white px-3 py-1"
                        type="button"
                        onClick={() => {
                          setRegisterModal(true);
                        }}
                      >
                        Register
                      </button>
                    ) : (
                      <div className="w-full flex items-center">
                        <div className="my-auto align-middle mx-auto">
                          {data?.isHost ? (
                            <p className="text-center text-green-600">
                              Already registered
                            </p>
                          ) : (
                            <p className="text-center text-green-600">
                              Already registered
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-full flex items-center">
                    <div className="my-auto align-middle mx-auto">
                      <p className="text-center text-red-600">
                        Registration closed
                      </p>
                    </div>
                  </div>
                )}
                <RegisterForm
                  data={data}
                  setIsRegistered={setIsRegistered}
                  showMessage={(arg) => {
                    if (arg.type === "success")
                      useSuccessToast({ message: arg.message });
                    else useErrorToast({ message: arg.message });
                  }}
                  openState={registerModal}
                  close={() => {
                    setRegisterModal(false);
                  }}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1400px] mx-auto px-4 md:px-6 lg:px-10">
        <div className="flex flex-col mt-8">
          <div className="flex gap-1 overflow-auto ml-auto mr-0">
            <button
              onClick={() => setActiveTab("about")}
              className={`px-2 py-1 text-xs sm:text-base sm:px-3 sm:py-1.5 ${activeTab === "about" ? "text-primary" : "text-gray-500"
                }`}
            >
              About
            </button>
            {dataFound &&
              ["scheduled"].includes(data?.registration?.status) && (
                <>
                  <button
                    onClick={() => setActiveTab("matches")}
                    className={`px-2 py-1 text-xs sm:text-base sm:px-3 sm:py-1.5 ${activeTab === "matches"
                      ? "text-primary"
                      : "text-gray-500"
                      }`}
                  >
                    Matches
                  </button>
                  {(data?.tournament_type === 't1' || data?.tournament_type === 't3') && (
                    <button
                      onClick={() => setActiveTab("scoreTable")}
                      className={`px-2 py-1 text-xs sm:text-base sm:px-3 sm:py-1.5 ${activeTab === "scoreTable" ? "text-primary" : "text-gray-500"}`}
                    >
                      Score Table
                    </button>
                  )}
                </>
              )}
          </div>
          <hr />
          <div className="mt-4">{renderContent(data, setShowMatchData, showMatchData)}</div>
        </div>
      </div>
    </>
  );
}

TournamentMain.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TournamentMain;
