import axios from "../../config/api";

export const getUserTournaments = async (query, auth) => {
  const result = await axios.get(`user/tournaments?${query}`, {
    headers: {
      Authorization: `Bearer ${auth?.accessToken}`,
    },
  });
  return result;
};

export const getUserTournament = async (id, auth) => {
  const result = await axios.get(`user/tournament/${id}`, {
    headers: {
      Authorization: `Bearer ${auth?.accessToken}`,
    },
  });
  return result;
};
