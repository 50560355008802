/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import InputFields, {
  CssTextField,
  InputSubmit,
} from "../InputFields/InputFields";
import cameraIcon from "../../assets/icons/camera.svg";
import playerSchema from "../../schema/user/playerSchema";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useErrorToast, useSuccessToast } from "../../hooks/useToast";
import CenterPopup from "../Popup/CenterPopUp";

function PlayerForm({ onClose, openState, data, profile, reRender }) {
  const [showImgErr, setShowImgErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(profile);
  const axios = useAxiosPrivate();
  const docRef = useRef();
  const [doc, setDoc] = useState(null);
  const [showDocErr, setShowDocErr] = useState(false);

  const handleUploadImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      setShowImgErr(false);
      setFile(image);
      setPhotoURL(URL.createObjectURL(image));
    }
  };

  const handleUploadDoc = (e) => {
    const gDoc = e.target.files[0];
    if (gDoc) {
      setShowDocErr(false);
      setDoc(gDoc);
    }
  };

  const formik = useFormik({
    initialValues: data,

    validationSchema: playerSchema,

    onSubmit: (values, { resetForm }) => {
      if (!file) setShowImgErr(true);
      else if (!doc) setShowDocErr(true);
      else {
        setLoading(true);
        setShowImgErr(false);
        // create a formData
        const formData = new FormData();
        // add files to formData
        formData.append("profile", file);
        formData.append("doc", doc);
        // add every values to formData
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        // post data
        axios
          .post("/user/player", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            reRender();
            useSuccessToast({ message: "New player added successfully" });
            resetForm({ values: "" });
            setPhotoURL("");
            setDoc(null);
            setFile(null);
            onClose();
          })
          .catch((err) => {
            const largErr = err?.response?.data?.error?.message;
            if (largErr && largErr.split(".")[0] === "File size too large")
              useErrorToast({ message: "Image size is too large" });
            else
              useErrorToast({
                message: err?.response?.data?.message || "Something went wrong",
              });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <CenterPopup
      title="Add a player"
      openState={openState}
      close={() => onClose(false)}
    >
      <div className="w-full">
        <form className="box-border">
          <div className="flex flex-col items-center">
            <div className="relative w-32 h-32">
              <input
                type="file"
                name="profile"
                ref={ref}
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleUploadImage}
              />
              <button
                type="button"
                onClick={() => {
                  ref.current.click();
                }}
                className={`${
                  showImgErr && "border-red-700 border-2"
                } relative w-full h-full border-2 rounded-full`}
              >
                <div
                  className="relative w-full h-full rounded-full flex justify-center items-center after:content-['']  after:absolute after:top-0 after:bottom-0 after:right-0 after:left-0"
                  style={{
                    backgroundImage: `url('${photoURL}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  {!photoURL && (
                    <img
                      src={cameraIcon}
                      alt=""
                      className="absolute top-0 bottom-0 mx-auto my-auto left-0 right-0"
                    />
                  )}
                </div>
              </button>
            </div>
            {showImgErr && (
              <p className="text-red-700 mt-2">Profile image is required</p>
            )}
          </div>
          <div className="flex flex-col items-center mt-4">
            <input
              type="file"
              name="document"
              ref={docRef}
              accept="application/pdf"
              style={{ display: "none" }}
              onChange={handleUploadDoc}
            />
            <button
              type="button"
              onClick={() => {
                docRef.current.click();
              }}
              className={`border ${
                showDocErr
                  ? "border-red-500 text-red-500"
                  : "border-slate-200 text-slate-500"
              } bg-slate-200 px-3 py-2 relative w-full max-w-md h-20 flex flex-col justify-center items-center rounded-md`}
            >
              Upload PDF of Age Proof
              {doc?.name && (
                <div className="h-5 mt-2">
                  <div className="text-black">{doc?.name}</div>
                </div>
              )}
            </button>
            {showDocErr && (
              <p className="text-red-700 mt-2">Document is required</p>
            )}
          </div>
          <CssTextField
            error={formik.errors.name && formik.touched.name}
            sx={{ marginTop: "12px" }}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.errors.name && formik.touched.name
                ? formik.errors.name
                : ""
            }
            label="Name"
            className="w-full mt-4"
          />
          <InputFields
            className="h-12 mt-3 w-full"
            type="date"
            holder="Date of birth"
            name="date_of_birth"
            max={new Date().toISOString().split("T")[0]}
            min="1953-01-01"
            value={formik.values.date_of_birth}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={
              formik.errors.date_of_birth && formik.touched.date_of_birth
                ? formik.errors.date_of_birth
                : ""
            }
          />
          <div className="w-full mt-4 flex justify-end">
            <InputSubmit
              className="w-32"
              loadingValue={loading ? "Loading..." : ""}
              value="Add"
              onClick={formik.handleSubmit}
            />
          </div>
        </form>
      </div>
    </CenterPopup>
  );
}

PlayerForm.defaultProps = {
  data: {
    name: "",
    date_of_birth: "",
  },
  profile: "",
  reRender: () => {},
};

PlayerForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  profile: PropTypes.string,
  reRender: PropTypes.func,
  openState: PropTypes.bool.isRequired,
};

export default PlayerForm;
